import * as d3 from "d3";
import { PubmedContent, ResultsData, TrialsContent } from "../LiteratureSearch/LiteratureSearch";
import { NodeData } from "./GraphGenerator";
import "./KnowledgeGraph.css";

// D3 scrollbar code modified from https://codepen.io/dabrorius/pen/EdQoYe

export interface ContextItem {id: string, title: string };
export interface ContextData {pubmed: ContextItem[], clinical_trials: ContextItem[]};
export interface menuDataType {
    id: string,
    rank: number,
    score: number,
    type: string,
    title: string,
    fullTitle: string,
    href: string,
    saved: boolean,
}
const CHARACTERS_IN_CONTEXT_ITEMS = 100;
const ZOOM_ICON = 'M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z';
const ZOOM_IN_ICON = 'M12 10h-2v2H9v-2H7V9h2V7h1v2h2v1z';
const SWITCH_ON_A = 'M12,6.5A5.37,5.37,0,0,0,13.26,10H5.5a3.5,3.5,0,1,1,0-7h7.77A5.4,5.4,0,0,0,12,6.5Z';
const SWITCH_ON_B = 'M22,6.07A4.5,4.5,0,0,0,17.5,2,4.52,4.52,0,0,0,13,6.5a4.4,4.4,0,0,0,1.36,3.21A4.45,4.45,0,0,0,17.5,11,4.5,4.5,0,0,0,22,6.93a2.08,2.08,0,0,0,0-.43A2.08,2.08,0,0,0,22,6.07Z';
const SWITCH_OFF_A = 'M 22 6.5 A 3.48 3.48 0 0 1 18.5 10 H 10.73 A 5.4 5.4 0 0 0 12 6.5 A 5.45 5.45 0 0 0 10.73 3 H 18.5 A 3.5 3.5 0 0 1 22 6.5 Z';
const SWITCH_OFF_B = 'M 6.5 2 A 4.5 4.5 0 0 0 2 6.07 a 3.1 3.1 0 0 0 0 0.86 A 4.5 4.5 0 0 0 6.5 11 a 4.44 4.44 0 0 0 3.13 -1.28 A 4.4 4.4 0 0 0 11 6.5 A 4.52 4.52 0 0 0 6.5 2 Z';

export const menuFactory = (
    x: number,
    y: number,
    svgBoxDimensions: Array<number>,
    data: NodeData,
    svgId: string,
    handleAddTermToSearchValue: Function,
    handleZoomInClick: Function,
    contextData: ContextData,
    handleSaveKGReference: Function,
    setReopenContextMenu: Function,
) => {
    const pubmedMenuItems: menuDataType[] = [];
    const trialMenuItems: menuDataType[] = [];

    if (data.pubmed_ids) {
        const contextPubmedIds = Array.from(new Set(data.pubmed_ids)).map((pubmedId: string) => {
            return contextData.pubmed.find((item: ContextItem) => item.id === pubmedId);
        });
        contextPubmedIds.forEach((result: ResultsData<PubmedContent> | any) => {
            const { title, id, saved, rank, score, type } = result;
            if (result) pubmedMenuItems.push({
                id,
                rank,
                score,
                type,
                title: title.length > CHARACTERS_IN_CONTEXT_ITEMS ? `${title.substring(0, CHARACTERS_IN_CONTEXT_ITEMS)}...` : title,
                fullTitle: title,
                href: `https://pubmed.ncbi.nlm.nih.gov/${id}`,
                saved: !!saved
            });
        });
    }

    if (data.trial_ids) {
        const contextTrialIds = Array.from(new Set(data.trial_ids)).map((trialId: string) => {
            return contextData.clinical_trials.find((item: ContextItem) => item.id === trialId);
        });
        contextTrialIds.forEach((result: ResultsData<TrialsContent> | any) => {
            const { title, id, saved, rank, score, type } = result;
            if (result) trialMenuItems.push({
                id,
                rank,
                score,
                type,
                title: title.length > CHARACTERS_IN_CONTEXT_ITEMS ? `${title.substring(0, CHARACTERS_IN_CONTEXT_ITEMS)}...` : title,
                fullTitle: title,
                href: `https://beta.clinicaltrials.gov/study/${id}`,
                saved: !!saved
            });
        });
    }

    let width: number = 0;
    const xOffset: number = x - 30;
    const boxHeight: number = 300;

    d3.select(`.${'context-menu'}`).remove();

    const svg = d3.select(svgId);
    const parent = svg
                    .append('g')
                    .attr('class', 'context-menu')
                    .attr('key', data.id)
                    .attr('transform', `translate(${x},${y})`);

    parent.append('rect')
        .attr("rx", 6)
		.attr("ry", 6)
        .attr('width', 50) //  Set the min width, max will be determined at run time
        .attr('height', boxHeight)
        .on('click', () => { d3.select(`.${'context-menu'}`).remove(); });

    const scrollgroup = parent.append('g')
        .attr('id', 'scrollgroup')
        .attr('width', 50)
        .attr('height', boxHeight)
        .attr("clip-path", "url(#scrollbox-clip-path)");

    scrollgroup.append('g')
        .attr('id', 'menu-entry')
        .attr('transform', 'translate(0,0)')
        .append('text')
        .attr('class', 'context-title context-position')
        .text((d: any) => `+ ${data.label}`)
        .attr('y', 8)
        .attr('dy', 20)
        .attr('dx', 25)
        .on('click', () => {
            handleAddTermToSearchValue(data)
        })

    const labelSpacing: number = 20;

    const hasTrialItems: boolean = trialMenuItems.length > 0;
    if (hasTrialItems) {
        d3.select('#menu-entry')
            .append('text')
            .attr('class', 'context-title context-position article-type')
            .text((d: any) => 'Clinical Trials')
            .attr('y', 6 + (labelSpacing * 1.5))
            .attr('dy', 20)
            .attr('dx', 25);
    }

    parent.append('clipPath')
        .attr('id', 'scrollbox-clip-path')
        .append('rect')
        .attr('width', 50)
        .attr('height', boxHeight);

    const scd = (docId: string, rank: number, score: number, type: string, saved: boolean, data: NodeData, x: number, y: number) => {
        handleSaveKGReference(docId, rank, score, type, saved, data, x, y)
    }

    if (trialMenuItems.length) createMenuItems(trialMenuItems, labelSpacing, hasTrialItems ? labelSpacing : 0, scd, data, x, y);
    const hasPubmedItems: boolean = pubmedMenuItems.length > 0;
    const trialSpacing = hasTrialItems ? (trialMenuItems.length * labelSpacing) + 10 + labelSpacing : 0;
    if (hasPubmedItems) {
        d3.select('#menu-entry')
                .append('text')
                .attr('class', 'context-title context-position article-type')
                .text((d: any) => 'Pubmed')
                .attr('y', 6 + (labelSpacing * 1.5) + trialSpacing)
                .attr('dy', 20)
                .attr('dx', 25);
    }
    if (pubmedMenuItems.length) createMenuItems(pubmedMenuItems, labelSpacing, trialSpacing + labelSpacing, scd, data, x, y);
    
    
    d3.selectAll('.context-menu rect')
        .attr("width", function() {
            const element: any = this;
            var item: any = element.parentNode;
            if (item.className.baseVal !== 'context-menu') {
                item = item.parentNode;
            }
            width = item.getBBox().width + 34;
            return width;
        });

        const scrollBarWidth = 8;
        const scrollbarSpacing = 4
        const scrollBar = parent.append('rect')
            .attr('id', 'scrollBar')
            .attr('ry', scrollBarWidth / 2)
            .attr('x', width - 32 - scrollBarWidth - scrollbarSpacing)
            .attr('y', (d: any, i: any) => (i * 30))
            .attr("stroke", "#a1a1a1")
            .attr("stroke-opacity", 0.4)
            .attr('fill', '#f0f0f0');

        d3.select('#menu-entry')
            .append('g')
            .attr('id', 'menu-zoom')
            .attr('transform', `translate(${width - 80},${6}) scale(1.4)`)
    
        d3.select('#menu-zoom')
            .append('path')
            .attr('fill', '#808080')
            .attr('stroke', 'transparent')
            .attr('d', ZOOM_ICON)
    
        d3.select('#menu-zoom')
            .append('path')
            .attr('d', ZOOM_IN_ICON)
            .attr('fill', '#808080')
            .attr('stroke', 'transparent')
    
        d3.select('#menu-zoom')
            .append('rect')
            .attr('width', 40).attr('height', 40)
            .attr('fill', 'transparent')
            .attr('stroke', 'transparent')
            .on('mouseover', function() {
                d3.select(this).style("cursor", "pointer"); 
            })
            .on('mouseout', function() {
                d3.select(this).style("cursor", "default"); 
              })
            .on('click', () => {
                handleZoomInClick({ label: data.label, id: data.id, group: data.group });
            })
            .attr('class', 'button left-button')

    // The max width has been exceeded, move to the Left
    if (x + width > svgBoxDimensions[2]) {
        x = x - width;
        parent.attr('transform', `translate(${x},${y})`);
    }

    // The max height has been exceeded, move up
    if (y + boxHeight > svgBoxDimensions[3]) {
        parent.attr('transform', `translate(${x},${y -  boxHeight})`);
    }

    d3.select('body')
        .on('click', async () => {
            await new Promise(r => setTimeout(r, 200));
            d3.select(`.${'context-menu'}`).remove();
            setReopenContextMenu(false);
        });

    const scrollgroup_box = scrollgroup.node()!.getBBox();
    const rootBBox: { [name: string]: number; } = {
        x: scrollgroup_box.x,
        y: scrollBar.node()!.getBBox().y + scrollbarSpacing,
        width: scrollgroup_box.width,
        height: scrollgroup_box.height,
        scrollbarSpacing: scrollbarSpacing
    };

    if (rootBBox.height > boxHeight) {
        const maxScroll = Math.max(rootBBox.height - boxHeight + (labelSpacing * 2), 0);
        const scrollbarHeight = Math.min((boxHeight * boxHeight) / rootBBox.height, boxHeight - 10);
        scrollBar.attr('height', scrollbarHeight).attr('width', scrollBarWidth).attr('y', rootBBox.y);

        // Set up scroll events
        parent.on('wheel', (e) => {
            e.stopPropagation();
            updateScrollPosition(e.deltaY, rootBBox, scrollbarHeight, maxScroll, boxHeight);
        });

        // Set up scrollbar drag events
        let x: any = d3.drag().on('drag', (event) => {
            updateScrollPosition((event.dy * maxScroll) / (boxHeight - scrollbarHeight), rootBBox, scrollbarHeight, maxScroll, boxHeight);
        })
        scrollBar.call(x);

        scrollDistance = 0;
    } else {
        scrollBar.remove();
    }
}

let scrollDistance: number = 0;
const updateScrollPosition = (diff: number, rootBBox: { [name: string]: number; }, scrollbarHeight: number, maxScroll: number, boxHeight: number) => {
    const spacing = rootBBox.scrollbarSpacing;
    scrollDistance += diff;
    scrollDistance = Math.min(maxScroll, Math.max(0, scrollDistance));

    d3.select('#menu-entry').attr('transform', `translate(0,${-scrollDistance})`)
    let scrollBarPosition = rootBBox.y;
    if (maxScroll > 0) {
        const le: { [name: string]: number; } = { // Linear Interpolation
            y1: rootBBox.y,
            y2: rootBBox.y + boxHeight - scrollbarHeight - (spacing * 2),
            x: scrollDistance,
            x1: 0.0,
            x2: maxScroll
        };
        scrollBarPosition = le.y1 + ( (le.x - le.x1) * ((le.y2 - le.y1) / (le.x2 - le.x1)) )
    }
    d3.select('#scrollBar').attr('y', scrollBarPosition);
}

const createMenuItems = (menuItems: menuDataType[], yOffset: number, prevItemsOffset: number,
    saveDocs: Function, data: NodeData, x: number, y: number) => {

    const mi = d3.select('#menu-entry')
        .selectAll('tmp')
        .data(menuItems).enter()
        .append('g')
        .attr('class', 'bullet-point');

    mi.append('circle')
        .attr('class', 'context-circle-position')
        .attr('cx', 24)
        .attr('cy', (d: menuDataType, i: number) => 40 + ((i + 1) * yOffset) + prevItemsOffset - 4)
        .attr('r', '1')

    mi.append('a')
        .attr('href', (d: menuDataType) => d.href)
        .attr('text', (d: menuDataType) => d.fullTitle)
        .attr('target', '_blank')
        .append('text')
        .text((d: menuDataType) => d.title)
        .attr('x', 5)
        .attr('y', (d: menuDataType, i: number) => 40 + (i * yOffset) + prevItemsOffset)
        .attr('dy', yOffset)
        .attr('dx', 25)

    mi.append('g')
        .attr('class', 'saved-switch')
        .attr('transform',  (d: menuDataType, i: number) => `translate(${710},${48 + (i * yOffset) + prevItemsOffset}) scale(1.3)`)
        .append('path')
        .attr('fill', (d: menuDataType) => d.saved ? '#3f51b5' : '#000000')
        .attr('d', (d: menuDataType) => d.saved ? `${SWITCH_ON_A}` : `${SWITCH_OFF_A}`)
        .attr('stroke', 'transparent')
        .style("opacity", (d: menuDataType) => d.saved ? 0.5 : 0.38)
        .on('click', (e: any, d: menuDataType) => {
            const { id, saved, rank, score, type } = d;
            e.stopPropagation();
            saveDocs(id, rank, score, type, !saved, data, x, y);
        });
    mi.append('g')
        .attr('class', 'saved-switch')
        .attr('transform',  (d: menuDataType, i: number) => `translate(${710},${48 + (i * yOffset) + prevItemsOffset}) scale(1.3)`)
        .append('path')
        .attr('fill', (d: menuDataType) => d.saved ? '#3f51b5': '#fafafa')
        .attr('d', (d: menuDataType) => d.saved ? `${SWITCH_ON_B}` : `${SWITCH_OFF_B}`)
        .attr('stroke', 'transparent')
        .on('click', (e: any, d: menuDataType) => {
            const { id, saved, rank, score, type } = d;
            e.stopPropagation();
            saveDocs(id, rank, score, type, !saved, data, x, y);
        });
}

export const createContextMenu = (
    event: any,
    d: any,
    widthOffset: number,
    heightOffset: number,
    svgId: string,
    handleAddTermToSearchValue: Function,
    handleZoomInClick: Function,
    svgBoxDimensions: Array<number>,
    contextData: ContextData,
    handleSaveKGReference: Function,
    setReopenContextMenu: Function,
) => {
    menuFactory(
        event.pageX - heightOffset,
        event.pageY - widthOffset,
        svgBoxDimensions,
        d,
        svgId,
        handleAddTermToSearchValue,
        handleZoomInClick,
        contextData,
        handleSaveKGReference,
        setReopenContextMenu,
    );
    event.preventDefault();
}
