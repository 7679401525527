import React, {useEffect, useRef, useState} from 'react';
import './LiteratureSearch.css';
import '../KnowledgeGraph/KnowledgeGraph.css';
import SearchBar from './SearchBar';
import {a11yProps, TabPanel} from './Tabs';
import AppBar from '@material-ui/core/AppBar';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Pagination from '@material-ui/lab/Pagination';
import {withStyles} from '@material-ui/core/styles';
import clsx from 'clsx';
import SearchParamsBar from './SearchParamsBar';
import ResultsList from '../ResultsList/ResultsList';
import Graph from '../KnowledgeGraph/Graph';
import * as auth from "../../auth/auth";
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import CaseSwitcher from "../CaseSwitcher/CaseSwitcher";
import Cookies from "js-cookie";
import {useAtom} from "jotai";
import {caseList, caseSavedDocsList, currentUserAtom, errorAtom, pageSizeState} from "../../state/State";
import * as service from "../../service/Service";
import packageJson from '../../../package.json';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { Box, IconButton, Typography } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ContextData } from '../KnowledgeGraph/KnowledgeGraphUtils';
import GetAppIcon from '@material-ui/icons/GetApp';
import YoutubeSearchedForIcon from '@material-ui/icons/YoutubeSearchedFor';
import { groupBy } from '../../utilities';
import { NodeData } from '../KnowledgeGraph/GraphGenerator';

let BASE_URL = process.env.REACT_APP_DOCKER_BASE_URL;
if (process.env.NODE_ENV && process.env.NODE_ENV === 'development') {
    BASE_URL = process.env.REACT_APP_DEV_BASE_URL;
}

export enum Relevance {
    NotSet = 'NotSet',
    Viewed = "Viewed",
    NotRelevant = "NotRelevant",
    Relevant = "Relevant"
}

export interface ResultsData<T> {
    id: string,
    content: T,
    rank: number,
    score: number,
    type: string,
    saved?: boolean,
    searchQuery?: string,
    relevance: Relevance
}

export interface PubmedContent {
    abstract: string,
    ageEligibility: Array<number>,
    annotations: AnnotationsData,
    authors: Array<any>,
    conclusions: any,
    copyrights: string,
    doi: string,
    journal: string,
    keywords: Array<any>,
    methods: any,
    pubdate: any,
    publicationTypes: Array<string>,
    pubmed_id: string,
    results: any,
    title: string,
    references: Array<Reference>,
}

export interface TrialsContent {
    BriefSummary: [string],
    BriefTitle: [string],
    Condition: Array<string>,
    DetailedDescription: [string],
    InterventionName: [string],
    InterventionOtherName: [string],
    Keyword: Array<string>,
    MaximumAge: [string],
    MinimumAge: [string],
    NCTId: [string],
    Phase: Array<string>,
    Rank: number,
    ageEligibility: Array<number>,
    annotations: AnnotationsData,
    Countries: Array<string>,
}

export interface AnnotationsData {
    disease?: {},
    drug?: {},
    gene?: {},
    mutation?: {},
    species?: {},

    [key: string]: any
}

export interface SelectedNode {
    count: number,
    group: string,
    id: number,
    label: string,
    pubmed_ids?: Array<string>,
    trial_ids?: Array<string>
}

export interface Reference {
    PMID: string,
    citation: string,
}

export interface CentralNode { label: string, id: number, group: string }

interface IProps {
    classes: {
        tabsBar: {},
        searchParamsBar: {},
        resultPanels: {},
    }
}

interface IState {
    selectedNodes: SelectedNode[],
    tabValue: number,
    headerHeight: number,
    slider: {
        search: {
            sliderAge: Array<number>,
            sliderPhase: Array<number>,
        },
        saved: {
            sliderAge: Array<number>,
            sliderPhase: Array<number>,
        }
    },
    checkboxes: {
        pubmed: boolean,
        trial: boolean,
        aus: boolean,
        [key: string]: boolean;
    },
    nodeCheckboxes: {
        show_diseases: boolean,
        show_drugs: boolean,
        show_genes: boolean,
        [key: string]: boolean;
    },
    data: ResultsData<PubmedContent | TrialsContent>[] | [],
    serverSidePagination: {totalPages: number,  currentPage: number, totalResults: number, pageSize: number},
    graph: { nodes: { id: number, label: string, group: string }[], edges: { source: number, target: number }[], context: ContextData },
    isLoading: boolean,
    noData: boolean,
    isDownloading: boolean,
    centralNode: CentralNode | '',
    linksFromCentralNode: number | '',
    searchedTerm: string,
    reopenContextMenu: false | { x: number, y: number, nodeData: NodeData }
}

const styles = () => ({
    tabsBar: {
        margin: '20px 40px 10px 40px'
    },
    resultPanels: {
        margin: '0 40px'
    },
    searchParamsBar: {
        margin: '20px 40px 0 40px',
    }
});

const LiteratureSearch: React.FC<IProps> = ({classes}) => {

    const [, setError] = useAtom(errorAtom);
    const [currentUser,] = useAtom(currentUserAtom)
    const [caseSavedDocs, setCaseSavedDocs] = useAtom(caseSavedDocsList)
    const [, setCaseListState] = useAtom(caseList);

    const [version, setVersion] = useState<String>('')
    const [selectedNodes, setSelectedNodes] = useState<SelectedNode[]>([]);
    const [tabValue, setTabValue] = useState<number>(1);
    const [headerHeight, setHeaderHeight] = useState<number>(0);
    const [slider, setSlider] = useState<IState['slider']>({
        search: {
            sliderAge: [0, 2],
            sliderPhase: [0, 4],
        },
        saved: {
            sliderAge: [0, 2],
            sliderPhase: [0, 4],
        },
    });
    const [checkboxes, setCheckboxes] = useState<IState['checkboxes']>({
        pubmed: true,
        trial: true,
        aus: true,
    });
    const [serverSidePagination, setServersidePagination] = useState<IState['serverSidePagination']>({totalPages: 0,  currentPage: 0, totalResults: 0, pageSize: 0});
    const [fullData, setFullData] = useState<IState['data']>([]); // All search data
    const [graph, setGraph] = useState<IState['graph']>({nodes: [], edges: [], context: {pubmed: [], clinical_trials: []}});
    const [nodeCheckboxes, setNodeCheckboxes] = useState<IState['nodeCheckboxes']>({show_diseases: true, show_drugs: true, show_genes: true})

    const graphRef = useRef<any>(null);
    const headerRef = useRef<any>(null);

    const [pageSize, setPageSize] = useAtom(pageSizeState);
    const [isLoading, setIsLoading] = useState<IState['isLoading']>(false);
    const [noData, setNoData] = useState<IState['noData']>(false);
    const [isDownloading, setIsDownloading] = useState<IState['isDownloading']>(false);
    const [centralNode, setCentralNode] = useState<IState['centralNode']>('')
    const [linksFromCentralNode, setLinksFromCentralNode] = useState<IState['linksFromCentralNode']>('');
    const [searchedTerm, setSearchedTerm] = useState<IState['searchedTerm']>('');
    const [reopenContextMenu, setReopenContextMenu] = useState<IState['reopenContextMenu']>(false);

    useEffect(() => {
        setHeaderHeight(headerRef.current?.offsetHeight || 0);
    }, []);

    const handleChangeSearchTerms = (updatedTerms: SelectedNode[]) => {
        setSelectedNodes(updatedTerms);
    }
    const fillArrayFromStartToEnd = (start: number, end: number): Array<number> => {
        return Array.from({length: end - start + 1}, (_, i) => i + start);
    }

    // For functionality of componentDidMount
    useEffect(() => {
        // Code to run on component mount
        // ...
        const username = Cookies.get('loginUsername');
        const password = Cookies.get('loginPassword');
        if (username === undefined || password === undefined) {
            window.location.replace("/login")
        }
        const token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64');


        fetch(`${BASE_URL}/login`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Basic ${token}`
            }
        }).then((res) => {
            if (res.status === 401) {
                Cookies.set('loginUsername', '');
                Cookies.set('loginPassword', '');
                window.location.replace("/login")
            } else if (res.status === 200 || res.status === 201) {
                setVersion(packageJson.version);
                service.loadCurrentCaseSavedDocsList().then((data) => {
                    setCaseSavedDocs([...data]);
                });

                service.loadCases(false).then((caseList) => {
                    setCaseListState([...caseList]);
                });
            }
        });


        return () => {
            // Code to run on component unmount (cleanup)
            // ...
        };
    }, []);


    // For functionality of componentDidMount/componentDidUpdate
    useEffect(() => {
        setHeaderHeight(headerRef?.current?.offsetHeight);
    });

    async function fetchResults(params: any, method: string): Promise<any> {

        const res = await fetch(`${BASE_URL}/${method}`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Basic ${auth.buildToken()}`
            },
            body: JSON.stringify(params)
        });
        if (res !== null && res.status === 200) {
            return res.json();
        } else if (res !== null && res.status === 401) {
            window.location.replace("/login")
        } else {
            console.error('Data retrieval error');
            setError({error: true, message: 'Data retrieval error.'})
        }
    }

    const handleAddTermToSearchValue = (addedTerm: SelectedNode) => {
        const selectedNodeIndex = selectedNodes.findIndex((node: SelectedNode) => node.id === addedTerm.id);
        if (selectedNodeIndex === -1) {
            const newData = selectedNodes?.concat(addedTerm);
            setSelectedNodes(newData)
        }
    }

    const addNodeColour = (nodes: { id: number, label: string, group: string }[], sn: SelectedNode[]) => {
        return sn.map((node: SelectedNode) => {
            const index = nodes.findIndex((el: { id: number, label: string, group: string }) => el.label === node.label);
            if (index !== -1) {
                node.group = nodes[index].group;
            }
            return node;
        })
    }

    const updateInitialNodeId = (nodes: { id: number, label: string, group: string }[], sn: SelectedNode[]) => {
        const initialNodeIndex = sn.findIndex((node: { id: number, label: string, group: string }) => { return node.id === -1 });
        if (initialNodeIndex > -1) {
            const updatedNode = nodes.find((node: { id: number, label: string, group: string }) => { return node.label === sn[initialNodeIndex].label })
            if (updatedNode) {
                const updatedSelectedNodes: SelectedNode[] = structuredClone(selectedNodes);
                updatedSelectedNodes[initialNodeIndex].id = updatedNode.id;
                setSelectedNodes(updatedSelectedNodes);
                return updatedSelectedNodes;
            }
        }
        return sn;
    }

    const setContextMenuSavedDocs = (context: any, csd: ResultsData<PubmedContent | TrialsContent>[]) => {
        for (let type in context) {
            for (let index in context[type]) {
                const matches = service.doesDocIdMatch(context[type][index], csd);
                if (matches) context[type][index].saved = true;
                if (!matches && context[type][index].saved) context[type][index].saved = false;
            }
        }
        return structuredClone(context);
    }

    const handleSearch = async () => {
        try {
            setFullData([]);
            setGraph({nodes: [], edges: [], context: {pubmed: [], clinical_trials: []}});
            const {sliderAge, sliderPhase} = slider.search;
            const documentFilters: string[] = Object.keys(checkboxes).filter((key: string) => checkboxes[key]);
            const phaseFilters: number[] = fillArrayFromStartToEnd(sliderPhase[0], sliderPhase[1]);
            const ageFilters: number[] = fillArrayFromStartToEnd(sliderAge[0], sliderAge[1]);
            const central_node: CentralNode = centralNode ? centralNode : {label: '', id: -1, group: ''};
            if (selectedNodes.length !== 0) {
                setIsLoading(true);
                const query = selectedNodes.map((node: SelectedNode) => node.label).join(' ');
                const data = await fetchResults({query, documentFilters, phaseFilters, ageFilters,
                    nodeFilters: nodeCheckboxes, page: 0, page_size: pageSize, central_node}, 'search');
                for (let index in data.results) {
                    if (service.doesDocIdMatch(data.results[index], caseSavedDocs)) {
                        data.results[index].saved = true;
                    }
                }
                setContextMenuSavedDocs(data.graph.context, caseSavedDocs);
                const sortedData = [...data.results].sort((a: any, b: any) => a.rank - b.rank);
                setSearchedTerm(selectedNodes.map((node: SelectedNode) => node.label ).join(' '));
                const updatedSelectedNodes1: SelectedNode[] = updateInitialNodeId(data.graph.nodes, selectedNodes);
                const updatedSelectedNodes2: SelectedNode[] = updateSelectedNodeIds(
                    data && data.graph && data.graph.nodes ? data.graph.nodes : '',
                    updatedSelectedNodes1
                );
                if (centralNode) updateCentralNode(centralNode, data.graph.nodes);
                const updatedSelectedNodes3: SelectedNode[] = addNodeColour(data.graph.nodes, updatedSelectedNodes2);
                setFullData(sortedData ? sortedData : []);
                setGraph(data && data.graph ? data.graph : {});
                if (!centralNode) setCentralNodeDefault(
                    data && data.graph && data.graph.nodes ? data.graph.nodes : '',
                    updatedSelectedNodes3
                );
                setSelectedNodes(updatedSelectedNodes3);
                setServersidePagination({...data.pagination, currentPage: data.pagination.currentPage});
                setIsLoading(false);
                if (data.results.length === 0) setNoData(true);

            } else {
                console.error('No search parameter');
            }
        } catch (error) {
            setError({error: true, message: `from handleSearch function: ${String(error)}`})
        }
    }

    const handleClearSearchField = () => {
        setSelectedNodes([]);
    }

    const handleChangeTabIndex = (event: React.ChangeEvent<{}>, newValue: number) => {
        //this.setState({tabValue: newValue});
        setTabValue(newValue);
    };

    const handleChangeCheckbox = (checkboxes: { pubmed: boolean, trial: boolean, aus: boolean }) => {
        setCheckboxes(checkboxes);
    }

    const handleChangeSlider = (inComingSlider: { sliderAge: Array<number>, sliderPhase: Array<number> }, type: string) => {
        setSlider({...slider, [type]: inComingSlider});
    }

    const setCentralNodeDefault = (nodes: any, updatedSelectedNodes: SelectedNode[]) => {
        // if there's no set central node, and if the selected node is in the nodes list, then set the selected node by default
        if (updatedSelectedNodes.length > 0) {
            const initialValue = nodes.findIndex((node: any) => {
                return node.id === updatedSelectedNodes[0].id && node.label === updatedSelectedNodes[0].label }
            ) > -1 ? { label: updatedSelectedNodes[0].label, id: updatedSelectedNodes[0].id, group: updatedSelectedNodes[0].group } : '';
            setCentralNode(initialValue);
        }
    }

    const updateSelectedNodeIds = (nodes: any, sn: SelectedNode[]) => {
        return sn.map((n: SelectedNode) => {
            const index = nodes.findIndex((node: any) => node.label === n.label);
            if (index > -1) 
                n.id = nodes[index].id;
            return n;
        });
    }

    const updateCentralNode = (cn: CentralNode | '', nodes: any) => {
        if (cn) {
            const index = nodes.findIndex((node: any) => node.label === cn.label);
            if (index > -1) {
                cn.id = nodes[index].id;
                cn.group = nodes[index].group;
            }
            return cn;
        }
    }

    const handleChangeNodeCheckbox = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const nodeFilters = {...nodeCheckboxes, [event.target.id]: event.target.checked};
        setNodeCheckboxes(nodeFilters);

        try {
            setGraph({nodes: [], edges: [], context: {pubmed: [], clinical_trials: []}});
            const {sliderAge, sliderPhase} = slider.search;
            const documentFilters: string[] = Object.keys(checkboxes).filter((key: string) => checkboxes[key]);
            const phaseFilters: number[] = fillArrayFromStartToEnd(sliderPhase[0], sliderPhase[1]);
            const ageFilters: number[] = fillArrayFromStartToEnd(sliderAge[0], sliderAge[1]);
            const central_node: CentralNode = centralNode ? centralNode : {label: '', id: -1, group: ''};
            if (selectedNodes.length !== 0) {
                setIsLoading(true);
                const query = selectedNodes.map((node: SelectedNode) => node.label).join(' ');
                const data = await fetchResults({query, documentFilters, phaseFilters, ageFilters,
                    nodeFilters, page_size: pageSize, central_node}, 'graph');

                for (let index in data.results) {
                    if (service.doesDocIdMatch(data.results[index], caseSavedDocs)) {
                        data.results[index].saved = true;
                    }
                }
                setContextMenuSavedDocs(data.graph.context, caseSavedDocs);
                setGraph(data && data.graph ? data.graph : {});
                const sortedData = [...data.results].sort((a: any, b: any) => a.rank - b.rank);
                setFullData(sortedData ? sortedData : []);
                setSearchedTerm(selectedNodes.map((node: SelectedNode) => node.label ).join(' '));
                updateSelectedNodeIds(
                    data && data.graph && data.graph.nodes ? data.graph.nodes : '',
                    selectedNodes
                );
                updateCentralNode(central_node, data.graph.nodes);
                setServersidePagination({...data.pagination, currentPage: data.pagination.currentPage});
                setIsLoading(false);
            } else {
                console.error('No search parameter');
            }
        } catch (error) {
            setError({error: true, message: `from handleChangeNodeCheckbox function: ${String(error)} ${console.trace()}`})
        }
    }

    const handleResultsPagination = async (event: any, page: number) => {
        try {
            setFullData([]);
            const {sliderAge, sliderPhase} = slider.search;
            const documentFilters: string[] = Object.keys(checkboxes).filter((key: string) => checkboxes[key]);
            const phaseFilters: number[] = fillArrayFromStartToEnd(sliderPhase[0], sliderPhase[1]);
            const ageFilters: number[] = fillArrayFromStartToEnd(sliderAge[0], sliderAge[1]);
            const central_node: CentralNode = centralNode ? centralNode : {label: '', id: -1, group: ''};
            if (selectedNodes.length !== 0) {
                setIsLoading(true);
                const query = selectedNodes.map((node: SelectedNode) => node.label).join(' ');
                const data = await fetchResults({query, documentFilters, phaseFilters, ageFilters,
                    nodeFilters: nodeCheckboxes, page: page - 1, page_size: pageSize, central_node}, 'search');
                for (let index in data.results) {
                    if (service.doesDocIdMatch(data.results[index], caseSavedDocs)) {
                        data.results[index].saved = true;
                    }
                }
                setContextMenuSavedDocs(data.graph.context, caseSavedDocs);
                const sortedData = [...data.results].sort((a: any, b: any) => a.rank - b.rank);

                setFullData(sortedData ? sortedData : []);
                setServersidePagination({...data.pagination, currentPage: data.pagination.currentPage});
                setIsLoading(false);
                if (data.results.length === 0) setNoData(true);

            } else {
                console.error('No search parameter');
            }
        } catch (error) {
            setError({error: true, message: `from handleResultsPagination function: ${String(error)}`})
        }
    }

    /**
     * Handler for marking saved docs tab.
     * @param docId
     * @param saved
     */
    const saveCaseDocs = (docId: string, rank: number, score: number, type: string, saved: boolean) => {
        if (!!saved) {
            // add doc. This cant happen as it will come from saved docs, therefore can only be a change to false.
        } else {
            // remove doc
            for (let indexDoc in fullData) {
                if (docId === fullData[indexDoc].id) {
                    fullData[indexDoc].saved = saved;
                }
            }
            service.removeDocumentFromCase(currentUser.current_case_title, docId)
                .then((updatedSavedDocList) => {
                    setCaseSavedDocs([...updatedSavedDocList.caseSavedDocs]);
                }).catch((error) => {
                setError({error: true, message: `from saveCaseDocs function: ${String(error)}`});
            });
        }
    }

    /**
     * Handler for marking a doc as saved in search docs tab.
     * @param docId
     * @param saved
     */
    const saveSearchDocs = (docId: string, rank: number, score: number, type: string, saved: boolean, nodeData: NodeData, x: number, y: number) => {
        const documentData = {id: docId, rank, score, type, saved, searchQuery: searchedTerm}
        const caseIndex = fullData.findIndex((el: ResultsData<PubmedContent | TrialsContent>) => el.id === docId);
        if (caseIndex >= 0) {
            // some documents from the context menu won't exist on the full data
            let updatedData = structuredClone(fullData);
            updatedData[caseIndex].saved = saved;
            setFullData(updatedData);
            if (!!saved) updatedData[caseIndex].searchQuery = searchedTerm;
        }
        if (!!saved) {
            service.addDocumentToCase(currentUser.current_case_title, documentData).then((caseList) => {
                if (!caseList.error) {
                    setCaseSavedDocs([...caseList.caseSavedDocs]);
                    const updated = setContextMenuSavedDocs(graph.context, [...caseList.caseSavedDocs]);
                    setGraph({...graph, context: updated});
                    setReopenContextMenu({ x, y, nodeData });
                }
            }).catch((error) => {
                setError({error: true, message: `from addDocumentToCase function: ${String(error)}`});
            });
        } else {
            service.removeDocumentFromCase(currentUser.current_case_title, documentData.id).then((caseList) => {
                if (!caseList.error) {
                    setCaseSavedDocs(caseList.caseSavedDocs);
                    const updated: any = setContextMenuSavedDocs(graph.context, [...caseList.caseSavedDocs]);
                    setGraph({...graph, context: updated});
                    setReopenContextMenu({ x, y, nodeData });
                }
            }).catch((error) => {
                setError({error: true, message: `from removeDocumentFromCase function: ${String(error)}`});
            });
        }
    }

    const handleRelevancyCaseTab = (index: any, relevance: Relevance) => {
        // This won't get called at this stage as the checkboxes are not displayed in case tab. Might change later.
        console.log(':::::::: handleRelevancyCaseTab')
    }

    const handleRelevancySearchTab = (index: number) => {
        let docObject = fullData[index];
        service.logRelevanceAssessment(searchedTerm, docObject.id, docObject.rank, docObject.relevance, docObject.type)
            .catch((reason) => {
                // TODO: Work out what to do on error?
                console.log('::::::::: Error occurred ', reason);
            });
        setFullData([...fullData])
    }

    const handleLogout = () => {
        auth.loggUserOut()
    }

    const handleDocumentViewed = (id: string, rank: number, type: string) => {
        service.logRelevanceAssessment(searchedTerm, id, rank, Relevance.Viewed, type)
            .catch((reason) => {
                // TODO: Work out what to do on error?
                console.log('::::::::: Error occurred ', reason);
            });
    }

    const pageSizeChange = (event: any) => {
        let targetValue = event.target.value;
        if (targetValue === '') {
            targetValue = 1000;
        }
        setPageSize(targetValue);
    }

    const downloadCaseDocs = () => {
        setIsDownloading(true);
        service.getCaseDocs().then((ris: any) => {
            const href = window.URL.createObjectURL(ris);
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', `${currentUser.current_case_title}_${new Date().toISOString().slice(0,10)}.ris`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            setIsDownloading(false);
        })
    }

    const reproduceSavedSearch = (searchTerm: string) => {
        setSelectedNodes([{count: 0, group: '', id: -1, label: searchTerm}]);
    }

    const { show_diseases, show_drugs, show_genes} = nodeCheckboxes;

    const caseSavedDocsGrouped: any = caseSavedDocs.length > 0 ? groupBy(caseSavedDocs, ({ searchQuery }: any) => searchQuery as any) : {};

    return (
        <div className="results-body">
            <div className="header" ref={headerRef}>

                <Button onClick={handleLogout} variant="contained" color="primary" style={{
                    margin: '10px',
                    float: "right"
                }}>
                    Logout
                </Button>
                <CaseSwitcher/>
                <SearchBar
                    selectedNodes={selectedNodes}
                    handleChangeSearchTerms={handleChangeSearchTerms}
                    handleSearch={handleSearch}
                    handleClearSearchField={handleClearSearchField}
                    nodes={graph.nodes}
                />
                    <div className='below-search-bar'>  
                        <Typography
                            className='search-results-returned'
                            variant='overline'
                        >
                            {serverSidePagination.totalResults > 0 && !isLoading ? `Search for '${searchedTerm}' returned ${serverSidePagination.totalResults} results` : ''}
                        </Typography>
                    <div/>
                </div>
                <div className={clsx(classes.tabsBar)}>
                    <AppBar position="static" color="default">
                        <Tabs
                            value={tabValue}
                            onChange={handleChangeTabIndex}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="fullWidth"
                            aria-label="search result tabs"
                            className="tabpanel"
                            style={{maxHeight: "50px"}}
                        >
                            <Tab label="Query Builder / Knowledge Graph" {...a11yProps(0)} />
                            <Tab label="Search" {...a11yProps(1)} />
                            <Tab label="Saved Documents" {...a11yProps(2)} />
                        </Tabs>
                    </AppBar>
                </div>
                {tabValue === 1 && <div className={clsx(classes.searchParamsBar)}>
                    <div style={{display: tabValue === 1 ? 'block' : 'none'}}>
                        <InputLabel id="page-size-select-helper-input-label" style={{
                            marginBottom: '5px',
                        }}>Page Size</InputLabel>
                        <Select
                            labelId="page-size-select-helper-label"
                            id="page-size-select-helper"
                            value={pageSize}
                            label="Page Size"
                            onChange={pageSizeChange}
                        >
                            <MenuItem value={1000}>
                                <em>All</em>
                            </MenuItem>
                            <MenuItem value={10}>10</MenuItem>
                            <MenuItem value={20}>20</MenuItem>
                            <MenuItem value={50}>50</MenuItem>
                            <MenuItem value={100}>100</MenuItem>
                        </Select>

                        <SearchParamsBar
                            slider={tabValue === 1 ? slider.search : slider.saved}
                            checkboxes={checkboxes}
                            handleChangeSlider={handleChangeSlider}
                            handleChangeCheckbox={handleChangeCheckbox}
                            searchOrSaved={tabValue === 1}
                            filterSearchResults={handleSearch}
                        />
                    </div>
                </div>}
                {tabValue === 2 &&
                    <Button
                        variant="outlined"
                        color="primary"
                        className='download-cases-btn'
                        onClick={downloadCaseDocs}
                        disabled={isDownloading}
                    >
                        Download Case Docs &nbsp;
                        <GetAppIcon style={{fontSize: 'xx-large'}}/>
                    </Button>
                }
            </div>
            {isLoading &&
                <div className='loading-container'>
                    <CircularProgress />
                </div>
            }
            {!isLoading && noData && fullData.length === 0 &&
                <div className='no-data'>No results found.</div>
            }
            <React.Fragment>
                <TabPanel value={tabValue} index={0}>
                    <div ref={graphRef} className="graph-ref"
                        style={{boxSizing: "border-box", height: `calc(100% - ${headerHeight + 80}px)`}}>
                            <Graph
                                linksData={graph.edges}
                                nodesData={graph.nodes}
                                getGraphContainerRef={() => graphRef}
                                handleAddTermToSearchValue={handleAddTermToSearchValue}
                                contextData={graph.context}
                                centralNode={centralNode}
                                setCentralNode={setCentralNode}
                                linksFromCentralNode={linksFromCentralNode}
                                setLinksFromCentralNode={setLinksFromCentralNode}
                                show_diseases={show_diseases}
                                show_drugs={show_drugs}
                                show_genes={show_genes}
                                handleChangeNodeCheckbox={handleChangeNodeCheckbox}
                                isLoading={isLoading}
                                saveSearchDocs={saveSearchDocs}
                                reopenContextMenu={reopenContextMenu}
                                setReopenContextMenu={setReopenContextMenu}
                            />
                    </div>
                </TabPanel>
                <div className="results-panel">
                    <TabPanel value={tabValue} index={1} dir={'ltr'}>
                        <ResultsList
                            data={fullData}
                            saveData={saveSearchDocs}
                            relevanceUpdate={handleRelevancySearchTab}
                            displayRelevanceButtons={true}
                            documentViewed={handleDocumentViewed}
                        />
                    </TabPanel>
                </div>
                <div className="results-panel">
                    <TabPanel value={tabValue} index={2} dir={'ltr'}>
                    {Object.keys(caseSavedDocsGrouped).map((searchTerm: any) =>
                        <React.Fragment key={searchTerm}>
                            <Box className='saved-query'>{`SEARCH TERM:  ${searchTerm}`}
                                <IconButton
                                    style={{float: 'right', marginTop: '-9px'}}
                                    onClick={() => reproduceSavedSearch(searchTerm)}
                                >
                                    <YoutubeSearchedForIcon fontSize='large' style={{color: '#a0a0a0'}}/>
                                </IconButton>
                            </Box>
                            <ResultsList
                                data={caseSavedDocsGrouped[searchTerm]}
                                saveData={saveCaseDocs}
                                relevanceUpdate={handleRelevancyCaseTab}
                                displayRelevanceButtons={false}
                                documentViewed={handleDocumentViewed}
                            />
                        </React.Fragment>
                    )}
                    </TabPanel>
                </div>
            </React.Fragment>
            <div className="pagination-container">
                <React.Fragment>
                    <div style={{
                        marginTop: '20px',
                        marginLeft: '10px',
                        marginBottom: '5px',
                    }}>
                        <InputLabel>V{version}</InputLabel>
                    </div>
                </React.Fragment>
                {!isLoading && fullData && fullData.length > 0 && tabValue === 1 &&
                    <React.Fragment>
                        <Typography className="results-count" variant="overline">{`Showing ${(serverSidePagination.currentPage * serverSidePagination.pageSize) + 1} - 
                            ${serverSidePagination.currentPage * serverSidePagination.pageSize + serverSidePagination.pageSize} of
                            ${serverSidePagination.totalResults} results`}</Typography>
                        <div className="pagination">
                            <Pagination onChange={handleResultsPagination} count={Math.ceil(serverSidePagination.totalResults / pageSize)}
                                        page={serverSidePagination.currentPage + 1}/>
                        </div>
                    </React.Fragment>
                }
                {/* Removing pagination for saved docs. Maybe reinstate it if needed later on.
                {data && data.length > 0 && tabValue === 2 && data.filter((el: any) => el.saved)?.length !== 0 &&
                    <React.Fragment>
                        <div
                            className="results-count">{`Showing ${(paginationCaseSaved.from + 1)} - ${paginationCaseSaved.to} of
                ${caseSavedDocs.length} results`}</div>
                        <div className="pagination">
                            <Pagination onChange={handleResultsPaginationCaseSaved} count={paginationCaseSaved.count}/>
                        </div>
                    </React.Fragment>
                }*/}

            </div>

        </div>
    );
}

export default withStyles(styles)(LiteratureSearch);
