import React, { useState } from "react";
import { NodeData, runForceGraph } from "./GraphGenerator";
import "./KnowledgeGraph.css";
import { ContextData } from "./KnowledgeGraphUtils";
import { Checkbox, FormControlLabel, IconButton, TextField, Typography } from "@material-ui/core";
import { ZoomOut } from "@material-ui/icons";
import { Autocomplete } from "@mui/material";
import { CentralNode } from "../LiteratureSearch/LiteratureSearch";

interface ForceGraphProps {
  linksData: any,
  nodesData: any,
  getGraphContainerRef: Function,
  handleAddTermToSearchValue: Function,
  contextData: ContextData,
  centralNode: CentralNode | '',
  setCentralNode: Function,
  linksFromCentralNode: number | '',
  setLinksFromCentralNode: Function,
  show_diseases: boolean,
  show_drugs: boolean,
  show_genes: boolean,
  handleChangeNodeCheckbox: Function,
  isLoading: boolean,
  saveSearchDocs: Function,
  reopenContextMenu: false | { x: number, y: number, nodeData: any },
  setReopenContextMenu: Function,
}

interface IState {
  nodesDisplayed: number
  nodesTotal: number
  centralNodeOptions: CentralNode[]
}

export default function Graph(
  { linksData,
    nodesData,
    getGraphContainerRef,
    handleAddTermToSearchValue,
    contextData,
    centralNode,
    setCentralNode,
    linksFromCentralNode,
    setLinksFromCentralNode,
    show_diseases,
    show_drugs,
    show_genes,
    handleChangeNodeCheckbox,
    isLoading,
    saveSearchDocs,
    reopenContextMenu,
    setReopenContextMenu,
  }: ForceGraphProps) {

    const [nodesDisplayed, setNodesDisplayed] = useState<IState['nodesDisplayed']>(0);
    const [nodesTotal, setNodesTotal] = useState<IState['nodesTotal']>(nodesData.length);
    const [centralNodeOptions, setCentralNodeOptions] = useState<IState['centralNodeOptions']>([]);
    
    const containerRef: any = React.useRef(getGraphContainerRef());
    const links = linksData.map((d: NodeData) => Object.assign({}, d));
    const nodes = nodesData.map((d: NodeData) => Object.assign({}, d));

  const getAssociatedNodeIds = (currentNodeID: number) => {
    return structuredClone(links).filter((l: any) => {
      return currentNodeID === l.source || currentNodeID === l.target
    }).map((l: any) => {return l.source === currentNodeID ? l.target : l.source });
  }

  const getConnectedNodes = (associatedNodeIds: number[]) => {
    return structuredClone(nodes).filter((n: any) => {
      return associatedNodeIds.findIndex((m: number) => { return m === n.id }) > -1;
    });
  }

  // const getConnectedLinks = (currentNodeID: number) => {
  //   return structuredClone(links).filter((l: any) => {
  //     return l.source === currentNodeID || l.target === currentNodeID
  //   })
  // }

  const filterLinksWithoutNode = (difference: number[]) => {
    return structuredClone(links).filter((l: any) => {
      // remove links that exist in the difference array
      return difference.findIndex((id: number) => { return id === l.target }) === -1 
      && difference.findIndex((id: number) => { return id === l.source}) === -1;
    });
  }

  // docId: string, rank: number, score: number, type: string, saved: boolean
  const handleSaveKGReference = (docId: string, rank: number, score: number, type: string, saved: boolean, nodeData: NodeData, x: number, y: number) => {
    saveSearchDocs(docId, rank, score, type, saved, nodeData, x, y);
  }

  const handleZoomInClick = (newCentralNode: CentralNode) => {
    setCentralNode(newCentralNode);
    if (centralNode && linksFromCentralNode) {        
      let associatedNodeIds: number[] = getAssociatedNodeIds(newCentralNode.id).concat(newCentralNode.id);

      for (let x = 1; x < linksFromCentralNode; x++) {
        let associations: number[] = []
        associatedNodeIds.forEach((id: number) => {
          associations = associations.concat(getAssociatedNodeIds(id));
        });
        associatedNodeIds = [...new Set([...associatedNodeIds ,...associations])];
      }

      const connectedNodes = getConnectedNodes(associatedNodeIds);
      let difference = structuredClone(nodes).map((n: any) => n.id).filter((x: number) => !associatedNodeIds.includes(x));
      const connectedLinks = filterLinksWithoutNode(difference)
      setNodesDisplayed(connectedNodes.length);

      if (containerRef.current) {
        runForceGraph(
          containerRef.current,
          connectedLinks,
          connectedNodes,
          handleAddTermToSearchValue,
          handleZoomInClick,
          newCentralNode,
          contextData,
          handleSaveKGReference,
          reopenContextMenu,
          setReopenContextMenu,
        );
      }
    }
  }

  const doesCentralNodeExist = (centralNode: CentralNode | '', nodes: any) => {
    return centralNode && nodes.findIndex((node: any) => node.id === centralNode.id && node.label === centralNode.label) > -1;
  }

  React.useEffect(() => {
    let destroyFn;
    const cnOptions = nodes.length > 0 ? nodes.map((opt: any) => { return { label: opt.label, id: opt.id, group: opt.group }}) : [];
    setCentralNodeOptions(cnOptions);
    let connectedNodes = [];
    let connectedLinks = [];

    const centralNodeExists = doesCentralNodeExist(centralNode, nodes);
    
    if (linksFromCentralNode && centralNode && centralNodeExists) {
      let associatedNodeIds: number[] = getAssociatedNodeIds(centralNode.id).concat(centralNode.id);
      for (let x = 1; x < linksFromCentralNode; x++) {
        let associations: number[] = []
        associatedNodeIds.forEach((id: number) => {
          associations = associations.concat(getAssociatedNodeIds(id));
        });
        associatedNodeIds = [...new Set([...associatedNodeIds ,...associations])];
      }

      connectedNodes = getConnectedNodes(associatedNodeIds);
      let difference = structuredClone(nodes).map((n: any) => n.id).filter((x: number) => !associatedNodeIds.includes(x));
      connectedLinks = filterLinksWithoutNode(difference)

    } else {
      connectedNodes = structuredClone(nodes);
      connectedLinks = structuredClone(links);
      if (!centralNodeExists)
        setLinksFromCentralNode('');
    }
    setNodesDisplayed(connectedNodes.length);

    if (containerRef.current) {
      const { destroy } = runForceGraph(
        containerRef.current,
        connectedLinks,
        connectedNodes,
        handleAddTermToSearchValue,
        handleZoomInClick,
        centralNode,
        contextData,
        handleSaveKGReference,
        reopenContextMenu,
        setReopenContextMenu,
      );
      destroyFn = destroy;
    }

    return destroyFn;
  }, [handleAddTermToSearchValue, linksFromCentralNode, centralNode]);

  return <React.Fragment>
    <div className="graph-tool-bar-container">
      <div className="colour-keys">
        <label className={'disease-colour colour-keys-label'} key={'disease'}>{'disease'}</label>
        <FormControlLabel 
                control={
            <Checkbox
                id="show_diseases"
                checked={show_diseases}
                color="primary"
                inputProps={{'aria-label': 'checkbox'}}
                onChange={(event) => handleChangeNodeCheckbox(event)}
            />}
            label={undefined}
            style={{ margin: 0 }}
        />
        <label className={'drug-colour colour-keys-label'} key={'drug'}>{'drug'}</label>
        <FormControlLabel 
                control={
            <Checkbox
                id="show_drugs"
                checked={show_drugs}
                color="primary"
                inputProps={{'aria-label': 'checkbox'}}
                onChange={(event) => handleChangeNodeCheckbox(event)}
            />}
            label={undefined}
            style={{ margin: 0 }}
        />
        <label className={'gene-colour colour-keys-label'} key={'gene'}>{'gene'}</label>
        <FormControlLabel 
                control={
            <Checkbox
                id="show_genes"
                checked={show_genes}
                color="primary"
                inputProps={{'aria-label': 'checkbox'}}
                onChange={(event) => handleChangeNodeCheckbox(event)}
        />}
            label={undefined}
            style={{ margin: 0 }}
        />
        <label className={'mutation-colour colour-keys-label'} key={'mutation'}>{'mutation'}</label>
        <label className={'species-colour colour-keys-label'} key={'species'}>{'species'}</label>
      </div>
      {<div className='graph-tool-bar'>
        <Typography className="nodes-displayed" variant="overline">{`Displaying ${nodesDisplayed} of ${nodesTotal} nodes`}</Typography>
        <div className="graph-tool">
          <Autocomplete
            id="central-node"
            value={centralNode && nodes.map((opt: any) => opt.id).findIndex((id: any) => { return id === centralNode.id }) > -1 ? centralNode : {label: '', id: 0, group: ''}}
            options={centralNodeOptions}
            getOptionLabel={(option: any) => option && option.label ? option.label : ''}
            renderInput={(params: any) => <TextField {...params} label="Central Node" variant="outlined" />}
            onChange={(event, value) => {
              setCentralNode(value ? value : '')
            }}
            isOptionEqualToValue={(option, value) => (value === undefined)? false : (option.label === '""')? false : option.label === value.label}
            renderOption={(props, option: CentralNode) => {
              const { label, group } = option;
              return (
                <span {...props} className={`MuiAutocomplete-option option-group-${group}`}>
                  {label}
                </span>
              );
            }}
            classes={{inputRoot: `autocomplete-input-${centralNode ? centralNode.group : ''}`}}
          />
        </div>
        <div className="graph-tool">
        <TextField
          id="central-node-links"
          label="Links from Central Node"
          type="number"
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          InputProps={{ inputProps: { min: 0, max: 3 } }}
          onChange={(event: any) => {
            const { value } = event.target;
            let v = value > 3 ? '' : value;
            v = value < 1 ? '' : v;
            setLinksFromCentralNode(v ? parseInt(v) : v);
          }}
          value={linksFromCentralNode}
        />
      </div>
      <IconButton
        className="zoom-btn"
        aria-label="toggle password visibility"
        onClick={() => { 
          setLinksFromCentralNode('');
        }}
      >
        <ZoomOut fontSize="large"/>
      </IconButton>
    </div>}
  </div>
  {!isLoading && nodes && links &&
    <div ref={containerRef} className="knowledge-graph-container"></div>
  }
  </React.Fragment>;
}
